import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { FlexBox } from 'app/components/Layout/FlexBox'
import disableScroll from 'disable-scroll'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
  languageCode: string
  thumbnails?: ImageProps[]
}

export const Mosaic = memo(function Mosaic({
  images,
  languageCode,
  thumbnails,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            disableScroll.off()
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {thumbnails ? (
        <Thumbs row wrap>
          {uniq(thumbnails).map((item, index) => (
            <Thumb
              key={index}
              onClick={() => {
                if (!modalGalleryStatus) {
                  setModalGalleryStatus(true)
                  disableScroll.on()
                } else {
                  setModalGalleryStatus(false)
                  disableScroll.off()
                }

                setClickedSlide(index)
                setTimeout(() => {
                  setClickedSlide(null)
                }, 100)
              }}
            >
              <LazyLoadComponent>
                <Image {...item} />
              </LazyLoadComponent>
            </Thumb>
          ))}
        </Thumbs>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              disableScroll.off()
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
            variant="dark"
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto 9.375rem;
  padding: 0 10.556vw;

  @media (max-width: 1199px) {
    margin: 5.625rem auto 7.5rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Thumbs = styled(FlexBox)`
  margin-right: -1.875rem;

  @media (max-width: 1199px) {
    margin-right: -1.5rem;
  }

  @media (max-width: 767px) {
    margin-right: -1.25rem;
  }
`

const Thumb = styled.div`
  width: calc(33.333% - 1.875rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  overflow: hidden;
  margin: 1.875rem 1.875rem 0 0;
  padding-bottom: 23%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(33.333% - 1.5rem);
    margin: 1.5rem 1.5rem 0 0;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 1.25rem);
    margin: 1.25rem 1.25rem 0 0;
    padding-bottom: calc(50% - 1.25rem);
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  @media (max-width: 1199px) {
    > div {
      &:first-of-type {
        top: 1.625rem;
        right: 1.875rem;
      }
    }
  }
`

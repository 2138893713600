import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  languageCode: string
  quotationPageURL?: string
  title?: string
}

export const BookingCTA = memo(function BookingCTA({
  cta,
  languageCode,
  quotationPageURL,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <IconLogo />

      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}
      {quotationPageURL || cta ? (
        <Buttons dial={5} row wrap>
          {cta ? <Button {...cta} /> : null}
          {quotationPageURL ? (
            <Button
              label={useVocabularyData('quotation', languageCode)}
              URL={quotationPageURL}
              variant="outline"
            />
          ) : null}
        </Buttons>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin: 9.375rem 0 6.25rem;
  padding: 5.0625rem 0 6.125rem;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0 5rem;
    padding: 3.75rem 0 4.375rem;

    > svg {
      width: 3.75rem;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 27.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  line-height: 4rem;
  margin: auto;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }

  @media (max-width: 767px) {
    max-width: none;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 3rem;

  a {
    margin: 0 0.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 2.625rem;

    a {
      width: 100%;
      margin: 0.375rem 0;
    }
  }
`

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Highlight } from './Highlight'

export interface Props {
  description?: string
  highlights?: string[]
  languageCode: string
  title?: string
}

export const RoomIntro = memo(function RoomIntro({
  description,
  highlights,
  languageCode,
  title,
}: Props) {
  return (
    <Container row space="between" stretch tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        {highlights ? (
          <>
            <FadeInUp>
              <Label>
                {useVocabularyData('highlights-title', languageCode)}
                <Line />
              </Label>
            </FadeInUp>
            <FadeInUp>
              <Hightlights>
                {highlights.map((item: any, index) => (
                  <Highlight key={index} highlight={item.label} />
                ))}
              </Hightlights>
            </FadeInUp>
          </>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 5.6875rem 0 0 10.556vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 0 0 1.875rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const RightSide = styled.div`
  width: calc(50% - 8.819vw);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.1875rem 10.556vw 6.1875rem 6.736vw;

  @media (max-width: 1199px) {
    width: calc(50% - 3.75rem);
    padding: 4.375rem 1.875rem 4.375rem 3rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-bottom: 2.8125rem;
`

const Hightlights = styled.div``

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo, Maximize, Star, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  image?: ImageProps
  mainService?: string
  mobileImage?: ImageProps
  pax?: string
  size?: string
  title: string
}

export const RoomHero = memo(function RoomHero({
  category,
  image,
  mainService,
  mobileImage,
  pax,
  size,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <>
      {mobileImage ? (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={mobileImage.src}
            imageSrcSet={mobileImage.srcSet}
          />
        </Helmet>
      ) : null}

      <Container>
        <Wrapper>
          <IconLogo />
          {category ? <Category>{category}</Category> : null}
          <Title>
            {title}
            <Line />
          </Title>
          <Info dial={5} row wrap>
            {size ? (
              <Size dial={4} row>
                <Maximize />
                <Label>{size}</Label>
              </Size>
            ) : null}
            {pax ? (
              <Pax dial={4} row>
                <Users />
                <Label>{pax}</Label>
              </Pax>
            ) : null}
            {mainService ? (
              <MainService dial={4} row>
                <Star />
                <Label>{mainService}</Label>
              </MainService>
            ) : null}
          </Info>
        </Wrapper>

        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            {mobileImage ? (
              <Picture>
                <LazyLoadImage effect="blur" {...mobileImage} />
              </Picture>
            ) : null}
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <Picture>
              {image ? <LazyLoadImage effect="blur" {...image} /> : null}
            </Picture>
          </Media>
        </MediaContextProvider>
      </Container>
    </>
  )
})

const Container = styled.section`
  margin-top: 9.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 8.125rem;
  }
`

const Wrapper = styled.div`
  font-weight: 500;
  padding: 0 3rem;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  line-height: 4rem;

  > div {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Info = styled(FlexBox)`
  margin-top: 2.9375rem;

  > div {
    &:last-of-type {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      width: 0.125rem;
      height: 1.1875rem;
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
      opacity: 0.16;
      margin: 0 1.875rem;
    }
    svg {
      width: auto;
      max-width: 1.5rem;
      height: 1.125rem;
      fill: ${({ theme }) => theme.colors.variants.neutralDark4};
      margin-right: 0.625rem;
    }
  }

  @media (max-width: 767px) {
    > div {
      width: 100%;
      justify-content: center;
      &:after {
        display: none;
      }
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  opacity: 0.6;
`

const Size = styled(FlexBox)``

const Pax = styled(FlexBox)`
  svg {
    height: 1.375rem;
  }
`

const MainService = styled(FlexBox)``

const Picture = styled.div`
  width: 100%;
  height: 66.7vh;
  max-height: 37.5rem;
  overflow: hidden;
  margin-top: 3.75rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 60vh;
    max-height: 34.375rem;
    margin-top: 3rem;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }

  @media (max-width: 767px) {
    height: 40vh;
  }
`

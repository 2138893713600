import styled from '@emotion/styled'
import { Check } from 'app/components/Icons'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  highlight: string
}

export const Highlight = memo(function Highlight({ highlight }: Props) {
  if (!highlight) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <Check />
      <Label>{highlight}</Label>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  &:first-of-type {
    margin-top: 0;
  }

  > svg {
    width: 1rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.625rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  opacity: 0.6;
`
